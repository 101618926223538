import * as React from 'react';

import { HorizontalYellowLineShape } from '../../Atoms/Icons/Icons';

import './ImplantationSingleCard.scss';
import Link from '../../Molecules/Link/Link';

const ImplantationSingleCard = ({ title, adresse, subtitle_filiale, tel1, tel2, email, website, url, NoDetailFilial }) => {

  return (
    <div className="implantation_single_card_component">

      <div className="text">

        {
          NoDetailFilial ? (
            <div>
              <h3 className="title">
                <span>
                  {title.length > 50 ? `${title.substring(0, 47)}...` : title}
                </span>
                <HorizontalYellowLineShape className="line" />
              </h3>
            </div>
          ) :
            <Link href={url}>
              <h3 className="title">
                <span>
                  {title.length > 50 ? `${title.substring(0, 47)}...` : title}
                </span>
                <HorizontalYellowLineShape className="line" />
              </h3>
            </Link>
        }
        {/* <Link href={url}>
          <h3 className="title">
            <span>
              {title.length > 50 ? `${title.substring(0, 47)}...` : title}
            </span>
            <HorizontalYellowLineShape className="line" />
          </h3>
        </Link> */}
        <p className='subtitle'>{subtitle_filiale}</p>
        {
          adresse?.processed &&
          <div className="adresse" dangerouslySetInnerHTML={{ __html: adresse.processed }} />
        }
        <p className="phone">
          {tel1 && 'Tel. ' + tel1}

          {
            tel2 &&
            <span dangerouslySetInnerHTML={{ __html: '<br />Tel. ' + tel2 }} />
          }
        </p>
        <p className="email">{email || ''}</p>
        <p className="web">{website || ''}</p>
      </div>
    </div>
  );
};

ImplantationSingleCard.default = {
  title: 'Default Title',
  adresse: 'Default Adresse',
  details: 'Tel. 01 00 00 00 00'
};

export default ImplantationSingleCard;
