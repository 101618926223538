import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import Dropdown from '../../../Molecules/Dropdown/Dropdown';
import ImplantationSingleCard from '../../../components/ImplantationSingleCard/ImplantationSingleCard';
import PageBannerMap from '../../../components/PageBannerMap/PageBannerMap';
import PageBanner from '../../../components/PageBanner/PageBanner';
import Seo from '../../../components/Seo/Seo';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import GraphRH from '../../../Molecules/GraphRH/GraphRH';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import useMedias from '../../../hooks/useMedias';
import {

	getBreadCrumb,

} from '../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const ImplantationPage = ({ data }) => {
	const intl = useIntl();
	const allSiegeData = data?.allSiegeData.edges;
	const implantationData = data?.implantationData.edges[0].node;
	const imagesArray = data.allImages.edges;
	const pageData = data?.implantationData?.edges[0].node;
	const allMenu = data.allMenu.edges;
	const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
	let siegeListArr = [];
	let dropDownArr = [];
	let counter = 1;
	const { getImage } = useMedias();
	const [siegeList, setSiegeList] = useState([]);
	const [dropDownList, setDropDownList] = useState([]);
	const [selectedContinent, setSelectedContinent] = useState('');
	const metaTags = implantationData.metatag;
	let metaTitle = '';
	let metaDesc = '';
	const bannerImage = pageData?.relationships?.field_image?.drupal_internal__mid;

	// console.log(getImage(
	// 	imagesArray,
	// 	pageData?.relationships?.field_image?.drupal_internal__mid
	// ))
	//console.log(data)

	metaTags.forEach((meta) => {
		if (meta.attributes.name === 'title') {
			metaTitle = meta.attributes.content;
		}
		if (meta.attributes.name === 'description') {
			metaDesc = meta.attributes.content;
		}
	});
	const addSiege = (siege) => {
		let continentAvail = siegeListArr.filter(
			(a) => a.continent === siege.node?.relationships.field_region?.name
		);
		let nodeObj = siege.node;
		Object.assign(nodeObj, { visuel: data.file?.publicURL });

		if (continentAvail.length > 0) {
			siegeListArr
				.filter((a) => a.continent === nodeObj?.relationships.field_region?.name)[0]
				?.others.push(nodeObj);
		} else {
			const continent = nodeObj?.relationships.field_region?.name
			// intl.formatMessage({
			// 	id: `implantation.continent.${nodeObj?.field_continent}`,
			// });
			siegeListArr.push({
				continent: nodeObj?.relationships.field_region?.name, //nodeObj?.field_continent,
				continentTrad: continent,
				others: [nodeObj],
			});
		}

		siegeListArr.sort((a, b) => a.continent?.localeCompare(b.continent));
		setSiegeList(siegeListArr);


	};

	useEffect(() => {
		allSiegeData.forEach((siege) => {
			addSiege(siege);
		});

		dropDownArr.push({
			id: 0,
			label: pageData.field_dropdown_label || intl.formatMessage({ id: 'implantation.select_continent' }),
		});

		siegeListArr.forEach(
			(siege) => {
				siege.continent != null &&

					dropDownArr.push({
						id: counter++,
						label: siege.continent,
					})
			}




		);
		setDropDownList(dropDownArr);
	}, []);

	const onChangeContinentDropdown = (continent_selected) => {
		setSelectedContinent(continent_selected);
	};

	const slider_setting_cards = {
		className: 'implantation_cards',
		centerMode: true,
		infinite: false,
		centerPadding: '20px',
		speed: 300,
		initialSlide: 0,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<Layout imagesArray={imagesArray}>
			<Seo
				title={implantationData?.field_metatag?.title ? implantationData?.field_metatag?.title : metaTitle}
				description={implantationData?.field_metatag?.description ? implantationData?.field_metatag?.description : metaDesc}
				url={
					process.env.GATSBY_METADATA_SITE_URL +
					implantationData.path?.langcode +
					implantationData.path?.alias
				}
			/>
			<div className={classNames('page_template', 'implantations_page')}>
				{
					bannerImage && !pageData.field_header_inverse ?
						<PageBanner
							visuel={getImage(
								imagesArray,
								pageData?.relationships?.field_image?.drupal_internal__mid
							)}
							alt={pageData.relationships?.field_image?.field_media_image?.alt || ''}
							scroll={true}

						>
							<div className="wrapper_page">
								<Breadcrumb
									page_banner={true}
									data={{
										parentPage: {
											title: breadCrumb?.parent?.name,
											url: breadCrumb?.parent?.link,
										},
										currentPage: {
											title: breadCrumb?.current?.name,
											url: breadCrumb?.current?.link,
										},
										locale: pageData.langcode,
									}}
								/>

							</div>

						</PageBanner> :
						<PageBannerMap
							showHideClass={true}
							arrowIconeWhite={true}
							iframeURL={!bannerImage ? intl.formatMessage({ id: 'implantation.iframeURL' }) : ''}
							visuel={bannerImage ? getImage(
								imagesArray,
								pageData?.relationships?.field_image?.drupal_internal__mid
							) : ''}

							breadcrumbData={{
								// parentPage: { title: intl.formatMessage({ id: 'group.label' }), url: '' },
								parentPage: {
									title: breadCrumb?.parent?.name,
									url: breadCrumb?.parent?.link,
								},
								currentPage: {
									title: implantationData.title,
									url:
										'/' +
										implantationData.path?.langcode +
										implantationData.path?.alias,
								},
								locale: implantationData.langcode,
							}}
						>
							<div className='wrapper_page'>
								{

									<TitlePage color='color_white' title={implantationData.title} />
								}
								{implantationData.relationships?.field_blocs?.map((block, i) => {
									if (block.__typename === 'block_content__block_chiffres_clefs') {
										return (
											<div key={i}>
												<div className='col_2'>
													<div className='col graph'>
														<GraphRH
															text={
																block.relationships?.field_values[0]?.field_legend
																	?.processed
															}
															jobs={
																block.relationships?.field_values[0]?.field_chiffre
															}
															textColor='color_white'
														/>
													</div>
													<div
														className='col text'
														dangerouslySetInnerHTML={{
															__html: block?.body?.processed,
														}}
													></div>
												</div>
											</div>
										);
									}
								})}
							</div>
						</PageBannerMap>
				}

				<section
					className={classNames('section_content', 'section_region_list')}
				>
					<div className='dropdown_container wrapper_page'>
						<Dropdown
							onChange={onChangeContinentDropdown}
							selected={selectedContinent}
							list={dropDownList}
						/>
					</div>
					{siegeList.map((siege, i) => (
						<div
							key={i}
							className={classNames(
								'continent_data',
								selectedContinent?.id === 0 || selectedContinent === ''
									? 'show'
									: selectedContinent?.label === siege.continentTrad
										? 'show'
										: 'hide'
							)}
							aria-live='polite'
						>
							<div className='wrapper_page'>
								<TitleSection
									title={siege.continentTrad}
									// description={
									// 	siege.others?.length +
									// 	' ' +
									// 	intl.formatMessage({
									// 		id: `implantation.nb_locations.${siege.others?.length > 1 ? 'plural' : 'singular'
									// 			}`,
									// 	})
									// }
									type='line1'
								/>
								<div className='listCardsDesktop'>
									{siege.others?.map((card, index) => (
										<ImplantationSingleCard
											key={index}
											title={card?.title}
											adresse={card?.field_address_complete || null}
											subtitle_filiale={card?.field_sous_titre_filiale}
											url={'/' + implantationData.langcode + (card?.path?.alias || '')}
											NoDetailFilial={card?.field_detail_filiale}
											tel1={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[0]
													: card?.field_telephone
											}
											tel2={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[1]
													: null
											}
											email={card.field_email}
											website={card.field_website}
										/>
									))}
								</div>
							</div>
							<div className='listCardsMobile'>
								<SliderComponent settings={slider_setting_cards}>
									{siege.others?.map((card, index) => (
										<ImplantationSingleCard
											key={index}
											title={card.title}
											adresse={card?.field_address_complete || null}
											subtitle_filiale={card?.field_sous_titre_filiale}
											url={'/' + implantationData.langcode + (card?.path?.alias || '')}
											NoDetailFilial={card?.field_detail_filiale}
											tel1={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[0]
													: card?.field_telephone
											}
											tel2={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[1]
													: null
											}
											email={card.field_email}
											website={card.field_website}
										/>
									))}
								</SliderComponent>
							</div>
						</div>
					))}
				</section>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query implatationTemplateQuery($locale: String!, $slug: String!) {
		implantationData: allNodePage(
			filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
		) {
			edges {
				node {
					title
					field_dropdown_label
					path {
						alias
						langcode
					}
					field_metatag {
						description
						title
					}
					metatag {
						attributes {
							content
							name
						}
					}
					field_type
					field_header_inverse
					langcode
					body {
						processed
					}
					relationships {
					    field_image {
						drupal_internal__mid
						field_media_image{
							alt
						}
						}
						field_blocs {
							__typename
							... on Node {
								... on block_content__block_chiffres_clefs {
									id
									body {
										processed
									}
									field_title {
										processed
									}
									relationships {
										field_values {
											field_chiffre
											field_legend {
												processed
											}
										}
									}
								}
							}
						}
				
					}
				}
			}
		}
		allSiegeData: allNodePage(
			filter: {
				langcode: { eq: $locale }
				field_type: { eq: "filiale" }
			}
			sort: {order: ASC, fields: title}
		) {
			edges {
				node {
					field_address
					field_city
					field_code_postale
					field_address_complete{
					 processed
          }
					field_email
					field_telephone
          field_type
					field_website
					title
					field_detail_filiale
					field_sous_titre_filiale
					path {
						alias
						langcode
					}
					langcode
					drupal_id

					relationships {
					        field_region {
            name
          }
					
					}
				}
			}
		}
	allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
  
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }

	}
`;

export default ImplantationPage;
